<template>
    <div>
        <div class="page-title">在线商店</div>

        <div class="store-container">
            <div class="store-card">
                <div class="store-thumb">
                    <template v-if=" info.avatar ">
                        <img :src=" info.avatar" alt="">
                    </template>
                </div>
                <div class="s-1">商店名称</div>
                <div class="store-name">{{ info.name }}</div>
                <div class="s-1">销售商品</div>
                <div class="goods-num">
                    <template v-if="info.goodsNum">
                        <router-link to="/ast/shop/goods">
                            {{ info.goodsNum }}件
                        </router-link>
                    </template>
                    <template v-else> -</template>
                </div>
                <div class="cb-text">
                    <img src="/assets/icons/store-icon.png" alt="">
                    微信小商店
                </div>
            </div>


            <a target="_blank" href="https://shop.weixin.qq.com/" class="btn-login">
                <img src="/assets/icons/store-shop-icon.png" alt="">
                登录店铺PC后台
            </a>
        </div>

    </div>
</template>

<script>
    import api from "../../../repo/api";

    export default {
        name: "Index",
        components: {},
        data() {
            return {
                info: {
                    name: '-',
                    goodsNum: 0,
                    avatar: null,
                }
            }
        },
        mounted() {
            this.getInfo()
        },
        methods: {
            getInfo() {

                let info = localStorage['wx_shop_info']

                info = info ? JSON.parse(info) : {}

                this.info = info

                api.get('/ast-app/shop-info', (data) => {

                    this.info.name = data.data.nickname
                    this.info.avatar = data.data.avatar
                    this.info.goodsNum = data.data.goods_num

                    localStorage['wx_shop_info'] = JSON.stringify(this.info)
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .store-container {
        width: 100%;
        max-width: 1000px;
        height: 616px;
        background-image: url("/assets/images/store-bg.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding-top: 52px;
    }

    .store-card {
        width: 290px;
        height: 432px;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 147px 147px 4px 4px;
        backdrop-filter: blur(20px);
        margin: auto;
        padding-top: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-image: url("/assets/icons/store-card-bg.png");
        background-size: 264px 90px;
        background-repeat: no-repeat;
        background-position: center 318px;
    }

    .store-thumb {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        overflow: hidden;
        background-color: #dddddd;
        margin-bottom: 26px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .s-1 {
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 20px;
        margin-bottom: 10px;
    }

    .store-name {
        margin-bottom: 16px;
        font-size: 20px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 28px;
    }

    .goods-num {
        font-size: 16px;
        font-weight: 500;

        a {
            color: #FFFFFF;
        }

        line-height: 22px;
        margin-bottom: 50px;
    }

    .cb-text {
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin-bottom: 24px;

        img {
            width: 24px;
            height: 24px;
            margin-right: 4px;
        }
    }

    .btn-login {

        margin: 59px auto 0;
        width: 160px;
        height: 32px;
        background: #FFFFFF;
        line-height: 32px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 20px;
            margin-right: 4px;
        }
    }
</style>